import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';



export default function Six(){
    return (
        <>

           <Container fluid className="__bg-secondary-miror-v2">
                <Container className="fade-in">
                    <Row className="justify-content-center">
                        <h2 className="__text-headline __text-drak text-center  __margin-bottom-5-wl">
                            Simak Apa Kata Mereka Tentang Love Training Sebelumnya…
                        </h2>
                    </Row>
                    <Row>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Saya bisa dengan mudah mengenal kekurangan dan kesalahan saya dalam kehidupan romansa yang selama ini membuat saya banyak gagal. Mindset saya pun lebih terbuka tentang kehidupan romansa saya ke depannya”</p>
                                    <p className="__text-testimoni"><b>- Ratih</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Manfaatnya saya mengetahui cara menumbuhkan benih cinta ke orang yang kita sayang, mulai belajar menumbuhkan ketertarikan dari tahap per tahap. Dan mulai tahu saat kita mendekati seseorang, hendaknya menggunakan pdkt emosi, bukan logika. Itu terasa sangat elegan, tanpa mengejar namun proaktif.”</p>
                                    <p className="__text-testimoni"><b> - Astini</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Saya bisa lebih jernih dalam berpikir sehingga tau cara menentukan apa yang harus saya lakukan, sehingga tidak lagi bucin dan berfokus mengharap hanya pada satu laki laki, melainkan berfokus untuk memprioritaskan tujuan pribadi, mengembangkan passion diri dan lebih bisa menghargai diri sendiri, karena saya mencintai diri saya sendiri melebihi siapa pun.”</p>
                                    <p className="__text-testimoni"><b>  - Anes</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Buat yang single kita bisa deh tuh terapin langkah"nya buat tanam benih ke dia. Lebih proaktif lagi misalkan. Tapi trainingnya bukan buat yang single aja, tapi yang udah double pun bisa untuk menjaga hubungan tetap hangat.” </p>
                                    <p className="__text-testimoni"><b>- Diannas</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“HARUS IKUT! Membuka wawasan & kesadaran banget untuk modal kita membangun hubungan romansa, jadi ga sabar buat praktek.”  </p>
                                    <p className="__text-testimoni"><b>- Indah Riyanti</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12} className="mb-5">
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">“Hanya buang2 waktu dan perasaan jika kalian masih ragu untuk mengikuti Attraction Mastery ini. Karna apa yang disampaikan semua 99% kita pasti mengalaminya dan kita akan dapat trik2 untuk bisa menyikapinya” </p>
                                    <p className="__text-testimoni"><b>- Fitri</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <p className="__text-testimoni">Dijamin dengan ikut training attraction mastery ini, gak akan kehabisan ide buat menarik perhatian si gebetan untuk terus balik lagi dan jadi diriku semenarik mungkin di hati mereka, tentu dengan menjadi diriku yg se-unpredictable mungkin :)” </p>
                                    <p className="__text-testimoni"><b>- Imas Rahadita</b></p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    
                </Container>
            </Container>

        </>
    )
}